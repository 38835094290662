import Container from '@atoms/ContainerAssessment'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

function Header({ logo, className, alt, shadow='shadow', padding = 'py-2',type='type1', logoWidth = 156, logoHeight = 60  }) {
    return (
        <div className={`${shadow} ${padding} ${className}`}>
            <Container type={type}>
                <Link href='https://www.lissun.app/'>
                    <a target='_blank'>
                        <Image src={`/images/${logo}`} alt={"alt"} width={logoWidth} height={logoHeight} objectFit='contain' objectPosition='left' />
                    </a>
                </Link>
            </Container>
        </div>
    )
}

export default Header